import React from 'react';
import classNames from 'classnames';

import './index.css';

const FormButton = ({type, color, size, onClick, children}) => {
  const classes = classNames({
    'FormButton--secondColor': color === 'second',
    'FormButton--thirdColor': color === 'third',
    'FormButton--firstColor': color === 'first',
    'FormButton--large': size === 'large',
    'FormButton--small': size === 'small'
  });

  return (<button
    className={`FormButton ${classes}`}
    type={type}
    onClick={onClick}
    >
      {children}
    </button>
  );
};

FormButton.defaultProps = {
  type: 'button',
  size: 'large',
  color: 'first'
};

export default FormButton;
