export const addBook = book => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('wishlist').add({
      title: book.title,
      author: book.author,
      inBookshelf: book.inBookshelf,
      format: book.format,
      pages: book.pages,
      synopsis: book.synopsis,
      timestamp: book.timestamp
    })
    .then(() => {
      dispatch({
        type: 'ADD_BOOK_TO_WISHLIST'
      });
    })
    .catch(error => {
      dispatch({
        type: 'ADD_BOOK_TO_WISHLIST_ERROR',
        error
      });
    });
  };
};

export const deleteBook = id => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('wishlist').doc(id).delete()
    .then(() => {
      dispatch({
        type: 'DELETE_BOOK_SUCCESS'
      });
    })
    .catch(error => {
      dispatch({
        type: 'DELETE_BOOK_ERROR',
        error
      });
    });
  }
}

export const resetAddBookSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_ADD_BOOK_TO_WISHLIST'
    });
  };
};
