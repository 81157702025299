import React from 'react';
import { NavLink } from 'react-router-dom';

import './index.css';

const SignedOutLinks = () => {
  return (
    <li className="SignedOutLinks--Item">
      <NavLink className="SignedOutLinks--SignInLink" to='/'>Se connecter</NavLink>
    </li>);
};

export default SignedOutLinks;
