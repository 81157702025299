import React from 'react';
import { NavLink } from 'react-router-dom';

import './index.css';

import FormButton from '../../../forms/FormButton';

const SuccessCard = ({text, buttonText, onClick, navigationLink, navigationText}) => {
  return (
    <div className="SuccessCard--Card">
      <p className="SuccessCard--Text">{text}</p>
      <FormButton color="first" onClick={onClick}>{buttonText}</FormButton>
      <NavLink className="SuccessCard--Link" onClick={onClick} to={navigationLink}>{navigationText}</NavLink>
    </div>
  );
};

export default SuccessCard;
