export const addBook = book => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('books').add({
      title: book.title,
      author: book.author,
      eval: book.eval,
      format: book.format,
      language: book.language,
      review: book.review,
      synopsis: book.synopsis,
      timestamp: book.timestamp
    })
    .then(() => {
      dispatch({
        type: 'ADD_BOOK',
        book
      });
    })
    .catch(error => {
      dispatch({
        type: 'ADD_BOOK_ERROR',
        error
      });
    });
  };
};

export const resetAddBookSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_ADD_BOOK_SUCCESS'
    });
  };
};
