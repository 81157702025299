import React from 'react';

import './index.css';

const FormInput = props => {
  return (
    <input
      className="FormInput"
      type={props.type}
      value={props.value}
      id={props.id}
      onChange={props.onChange}
      name={props.name}
      title={props.title}
      placeholder={props.placeholder}
      minLength={props.minLength}
      maxLength={props.maxLength}
      pattern={props.pattern}
      required={props.required}
    />
  );
};

FormInput.defaultProps = {
  type: 'text'
};

export default FormInput;
