import React from 'react';
import './index.css';

const Spinner = () => {
  return (
    <div className="Spinner">
      <div className="Spinner--bounce1"></div>
      <div className="Spinner--bounce2"></div>
      <div className="Spinner--bounce3"></div>
    </div>
  );
};

export default Spinner;
