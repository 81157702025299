import React, { Component } from 'react';

import Bullet from './components/Bullet';

import './index.css';

class BookRating extends Component {
  renderFullBullets = () => {
    let fullBullets = [];
    let rating = this.props.rating;
    for(let i = 0; i < rating; i++) {
      fullBullets.push(<Bullet key={`full-${this.props.index}-${i}`} type="full" />);
    }
    return fullBullets;
  }

  renderEmptyBullets = () => {
    let emptyBullets = [];
    let nbEmptyBullets = 5 - this.props.rating;
    for(let i = 0; i < nbEmptyBullets; i++) {
      emptyBullets.push(<Bullet key={`empty-${this.props.index}-${i}`} type="empty" />);
    }
    return emptyBullets;
  }

  render() {
    return (
      <div className="BookRating--rating">
        {this.renderFullBullets()}
        {this.renderEmptyBullets()}
      </div>
    );
  }
}

export default BookRating;
