import React, { Component } from 'react';
import { connect } from 'react-redux';

import SignedInLinks from './components/SignedInLinks';
import SignedOutLinks from './components/SignedOutLinks';

import './index.css';

class Navbar extends Component {
  renderLinks = () => {
    const { auth } = this.props;

    if (auth.uid) {
      return (
        <ul className="Navbar--Menu">
          <SignedInLinks />
        </ul>
      );
    }

    return (
      <ul className="Navbar--Menu">
        <SignedOutLinks />
      </ul>
    );
  }

  render() {
    return (
      <div className="Navbar--Container">
        {this.renderLinks()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default connect(mapStateToProps)(Navbar);
