import React from 'react';

import './index.css';

const RadioGroup = props => {
  return (
    <fieldset className="RadioFieldset">
      <legend className="FieldsetLegend">{props.legend}</legend>
      {props.children}
    </fieldset>
  );
};

export default RadioGroup;
