import React, {useState} from 'react';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

import sort from 'fast-sort';

import Head2 from '../../../common/components/Head2';
import Head3 from '../../../common/components/Head3';
import BookDate from '../BookDate';
import BookRating from '../BookRating';
import BookReview from '../BookReview';
import Spinner from '../../../common/components/Spinner';
import Control from '../../../common/components/Control';

import './index.css';

export const BooksList = props => {
  const [displayAllDetails, setDisplayAllDetails] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortEntry, setSortEntry] = useState('timestamp');

  const formatDate = timestamp => {
    if(timestamp) {
      let months = ['Jan.', 'Fév.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];
      let readDate = new Date(timestamp);
      let month = months[readDate.getMonth()];
      let year = readDate.getFullYear();

      return {
        month,
        year,
        fullDate: `${month} ${year}`
      };
    }

  }

  const toggleDetails = id => {
    const details = id === displayDetails ? '' : id;
    setDisplayDetails(details);
  }

  const toggleAllDetails = () => {
    setDisplayAllDetails(!displayAllDetails);
    setDisplayDetails('');
  }

  const renderSortBar = () => {
    const total = `${props.books.length} livres lus`;

    return (
      <div className="BooksList--header">
        <p className="BooksList--total">{total}</p>
        <button className="BooksList--button" onClick={toggleAllDetails}>Détails</button>
        <select className="BooksList--button" onChange={e => handleChange(setSortEntry, e)}>
          <option value="timestamp">Trier par date</option>
          <option value="author">Trier par auteur</option>
        </select>
        <select className="BooksList--button" name="sortOrder" onChange={e => handleChange(setSortOrder, e)}>
          <option value="desc">Décroissant</option>
          <option value="asc">Croissant</option>
        </select>
      </div>
    );
  }

  const handleChange = (setFunc, e) => {
    const value = e.target.value;
    setFunc(value);
  }

  const sortList = books => {
    const entry = sortEntry;
    // Because the array is frozen in strict mode, we need to copy the array before sorting it
    books = books.slice();

    if(sortOrder === 'asc') {
      return sort(books).asc(entry);
    }

    return sort(books).desc(entry);
  }

  const renderReview = book => {
    return displayDetails === book.id || displayAllDetails ? <BookReview review={book.review} synopsis={book.synopsis} /> : null;
  };

  const { auth, books } = props;

  if (!auth.uid) {
    return <Redirect to='/' />
  }

  if (!books) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      {renderSortBar()}
      <ul className="Bookslist--list">
        {books && sortList(books).map((book, index) => {
          const date = formatDate(book.timestamp).fullDate;
          return (
            <React.Fragment key={index}>
              <li className="BooksList--listItem">
                <a className="BooksList--link" onClick={() => toggleDetails(book.id)}>
                  <div className="BooksList--col1">
                    <BookDate date={date} />
                    <BookRating index={index} rating={book.eval} />
                  </div>
                  <div className="BooksList--col2">
                    <Head2>{book.title}</Head2>
                    <Head3>{book.author}</Head3>
                    {renderReview(book)}
                  </div>
                </a>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
      <Control scroll={true} addReadBook={true} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    books: state.firestore.ordered.books
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'books' }
  ])
)(BooksList);
