import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import './index.css';

import {signOut} from '../../../../store/actions/authActions';

const SignedInLinks = props => {
  return (
    <React.Fragment>
      <li className="SignedInLinks--item"><NavLink className="SignedInLinks--Links" to='/books/list'>Livres lus</NavLink></li>
      <li className="SignedInLinks--item"><NavLink className="SignedInLinks--Links" to='/wishlist/list'>A lire</NavLink></li>
      <li className="SignedInLinks--item"><a onClick={() => {props.signOut()}} className="SignedInLinks--Links SignedInLinks--SignOutLink">Déconnexion</a></li>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(null, mapDispatchToProps)(SignedInLinks);
