import React from 'react';

import './index.css';

const FormLabel = props => {
  return (
    <label
     htmlFor={props.htmlFor}
     className={`FormLabel ${props.className}`}
     name={props.name}
    >
      {props.children}
    </label>
  );
};

export default FormLabel;
