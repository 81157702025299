const initialState = {};

const bookReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'ADD_BOOK':
      return {
        ...state,
        addBookSuccess: true
      };
    case 'ADD_BOOK_ERROR':
      return {
        ...state,
        addBookSuccess: false
      };
    case 'RESET_ADD_BOOK_SUCCESS':
      return {
        ...state,
        addBookSuccess: false
      }
    default:
      return state;
  }
};

export default bookReducer
