import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var config = {
  apiKey: "AIzaSyA1m3zQ3OGYXgxtcIo6CDkGq_fQV0KC1Vw",
  authDomain: "shining-inferno-3668.firebaseapp.com",
  databaseURL: "https://shining-inferno-3668.firebaseio.com",
  projectId: "shining-inferno-3668",
  storageBucket: "shining-inferno-3668.appspot.com",
  messagingSenderId: "592260919618"
};
firebase.initializeApp(config);
firebase.firestore();

export default firebase;
