import React from 'react';

import './index.css';

const BookReview = props => {
  return (
    <React.Fragment>
      <p className="BookReview--review">{props.review}</p>
      {props.synopsis ? <p className="BookReview--review BookReview--synopsis">{props.synopsis}</p> : null}
    </React.Fragment>
  );
}

export default BookReview;
