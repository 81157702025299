import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { addBook, resetAddBookSuccess } from '../../../../store/actions/bookActions';

import './index.css';

import SuccessCard from '../../../common/components/SuccessCard';
import ErrorMessage from '../../../common/components/ErrorMessage';
import RadioGroup from '../../../forms/RadioGroup';
import FormButton from '../../../forms/FormButton';
import FormTextarea from '../../../forms/fields/FormTextarea';
import InputGroup from '../../../forms/InputGroup';
import FormLabel from '../../../forms/fields/FormLabel';
import FormInput from '../../../forms/fields/FormInput';

class AddBooks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      author: '',
      eval: '',
      date: '',
      format: '',
      language: '',
      review: '',
      synopsis: '',
      timestamp: '',
      isbn: '',
      errors: [],
      googleApiError: '',
      showIsbnForm: false
    };

    this.baseState = this.state;
  }

  handleIsbnSearch = (event) => {
    event.preventDefault();

    if(this.state.isnb !== '') {
      this.setState({
      });

      fetch(`https://www.googleapis.com/books/v1/volumes?q=isbn:${this.state.isbn}&fields=totalItems,items(volumeInfo(title,authors,description))`)
      .then(response => response.json())
      .then(book => {
        if(book.totalItems !== 0) {
          this.setState({
            title: book.items[0].volumeInfo.title,
            author: book.items[0].volumeInfo.authors,
            synopsis: book.items[0].volumeInfo.description,
            googleApiError: ''
          })
        } else {
          this.setState({googleApiError: 'Il n’y a aucun résultat pour cette recherche'})
        }
      })
      .catch(error => {
        this.setState({googleApiError: error});
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let errors = [];

    const timestamp = this.createTimestamp();

    if (!this.state.title) {
      errors.push('title_blank');
    }

    if (!this.state.author) {
      errors.push('author_blank');
    }

    if (!this.state.eval) {
      errors.push('eval_blank');
    }

    if (!this.state.format) {
      errors.push('format_blank');
    }

    if (!this.state.language) {
      errors.push('language_blank');
    }

    if (!this.state.date) {
      errors.push('date_blank');
    }

    if (this.state.date && !timestamp) {
      errors.push('timestamp_bad_format');
    }

    if (!this.state.review) {
      errors.push('review_blank');
    }

    let ev = parseInt(this.state.eval, 10);
    let data = {
      title: this.state.title,
      author: this.state.author,
      eval: ev,
      format: this.state.format,
      language: this.state.language,
      review: this.state.review,
      synopsis: this.state.synopsis,
      timestamp
    };

    errors.length > 0 ? this.setState({errors}) : this.props.addBook(data);
  }

  displayError = (regex, message = 'Champ obligatoire') => {
    const hasError = this.state.errors.filter(err => regex.test(err));
    return hasError.length !== 0 ? <ErrorMessage message={message} /> : null;
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value
    });
  }

  setTodayDate = () => {
    const today = new Date();
    const day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();

    if(month < 10) {
      month = `0${month}`;
    }

    this.setState({ date: `${day}/${month}/${year}` });
  };

  createTimestamp = () => {
    const date = this.state.date;
    const dateIsValid = /(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(1[9][0-9][0-9]|2[0][0-2][0-9])/.test(date);

    if(dateIsValid) {
      const dateArray = date.split('/');
      const readDate = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
      const timestamp = new Date(readDate).getTime();

      return timestamp;
    }

    return null;
  }

  renderIsbnSearchButton = () => {
    return <FormButton color="second" onClick={() => this.setState({showIsbnForm: !this.state.showIsbnForm})}>Rechercher par ISBN</FormButton>;
  }

  renderIsbnForm = () => {
    return (
      <form onSubmit={this.handleIsbnSearch} ref={(form) => this.form = form} className="AddBook--IsbnForm">
        <InputGroup>
          <FormLabel htmlFor="isbn" name="isbn">Recherche par ISBN 10</FormLabel>
          <FormInput type="text" name="isbn" id="isbn" minLength="1" value={this.state.isbn} onChange={this.handleChange} required={true} />
          {this.state.googleApiError ? <ErrorMessage message={this.state.googleApiError} /> : null}
        </InputGroup>
        <FormButton type="submit" color="first">Rechercher</FormButton>
      </form>
    );
  }

  renderRadioButtons = (name, data) => {
    return data.map((d, index) => {
        return (
          <React.Fragment key={`${name}-${index}`}>
            <FormInput type="radio" value={d} id={`${name}-${d}`} name={name} onChange={this.handleChange} />
            <FormLabel htmlFor={`${name}-${d}`}>{d}</FormLabel>
          </React.Fragment>
        );
      });
  }

  resetForm = () => {
    this.setState(
      this.baseState,
      () => { this.form.reset(); }
    );
    this.props.resetAddBookSuccess();
  }

  renderForm = () => {
    return (
      <React.Fragment>
        {this.state.showIsbnForm ? this.renderIsbnForm() : this.renderIsbnSearchButton()}

        <form onSubmit={this.handleSubmit} ref={(form) => this.form = form}>
          <InputGroup>
            <FormLabel htmlFor="title" name="title">Titre</FormLabel>
            <FormInput type="text" name="title" id="title" value={this.state.title} onChange={this.handleChange} />
            {this.displayError(/title_/)}
          </InputGroup>

          <InputGroup>
            <FormLabel htmlFor="author" name="author">Auteur</FormLabel>
            <FormInput type="text" id="author" name="author" value={this.state.author} onChange={this.handleChange} />
            {this.displayError(/author_/)}
          </InputGroup>

          <RadioGroup legend="Note">
            {this.renderRadioButtons('eval', ['1', '2', '3', '4', '5'])}
            {this.displayError(/eval_/)}
          </RadioGroup>

          <RadioGroup legend="Format">
            {this.renderRadioButtons('format', ['Poche', 'Kindle'])}
            {this.displayError(/format_/)}
          </RadioGroup>

          <RadioGroup legend="Langue">
            {this.renderRadioButtons('language', ['Français', 'Anglais'])}
            {this.displayError(/language_/)}
          </RadioGroup>

          <InputGroup>
            <FormLabel htmlFor="date" name="date">Date de lecture</FormLabel>
            <FormButton color="second" onClick={this.setTodayDate} size="small">Aujourd'hui</FormButton>
            <FormInput type="tel" name="date" id="date" title="JJ/MM/AAAA" value={this.state.date} onChange={this.handleChange} />
            {this.displayError(/date_/)}
            {this.displayError(/timestamp_bad_format/, 'Mauvais format de date')}
          </InputGroup>

          <InputGroup>
            <FormLabel htmlFor="review">Commentaires</FormLabel>
            <FormTextarea id="review" name="review" rows="3" value={this.state.review} onChange={this.handleChange}></FormTextarea>
            {this.displayError(/review_/)}
          </InputGroup>

          <InputGroup>
            <FormLabel htmlFor="synopsis">Synopsis</FormLabel>
            <FormTextarea id="synopsis" name="synopsis" rows="3" value={this.state.synopsis} onChange={this.handleChange}></FormTextarea>
          </InputGroup>

          <FormButton type="reset" color="second" onClick={this.resetForm}>Reset</FormButton>
          <FormButton type="submit" color="first">Ajouter</FormButton>
          </form>
      </React.Fragment>
    );
  }

  render() {
    const { auth, addBookSuccess } = this.props;

    if (!auth.uid) {
      return <Redirect to='/' />;
    }

    return (
      <div className="AddBooks">
        { addBookSuccess
          ? <SuccessCard
             onClick={this.resetForm}
             text="Le livre a bien été ajouté !"
             buttonText="Ajouter un nouveau livre"
             navigationLink="/books/list"
             navigationText="Retourner sur la liste"
            />
          : this.renderForm()
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    addBookSuccess: state.book.addBookSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBook: book => dispatch(addBook(book)),
    resetAddBookSuccess: () => dispatch(resetAddBookSuccess())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBooks);
