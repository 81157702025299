export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({
        type: 'SIGNIN_SUCCESS'
      });
    })
    .catch(error => {
      dispatch({
        type: 'SIGNIN_ERROR',
        error
      });
    })
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signOut()
    .then(() => {
      dispatch({
        type: 'SIGNOUT_SUCCESS'
      });
    })
    .catch(() => {
      dispatch({
        type: 'SIGNOUT_ERROR'
      });
    });
  };
};
