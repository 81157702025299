import React from 'react';
import {NavLink} from 'react-router-dom';

import ArrowIcon from './assets/arrow.svg';
import AddIcon from './assets/add.svg';

import './index.css';

const Control = ({addReadBook, addToWishlist, scroll}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const renderAddIcon = () => {
    if (addReadBook || addToWishlist) {
      const path = addReadBook ? '/books/add' : addToWishlist ? '/wishlist/add' : '';
      return <NavLink className="Control--Link Control--ButtonStyles" to={path}><AddIcon width="25" height="25" /></NavLink>;
    }
    return null;
  };

  return (
    <div className="Control--Fixed">
      {renderAddIcon()}
      {scroll ? <button className="Control--Button Control--ButtonStyles" onClick={scrollToTop}><ArrowIcon width="25" height="15" /></button> : null}
    </div>
  );
};

Control.defaultProps = {
  addReadBook: false,
  addToWishlist: false,
  scroll: false
};

export default Control;
