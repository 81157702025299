import React, {Component} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from './components/Header';
import BooksList from './components/Books/components/BooksList';
import AddBook from './components/Books/components/AddBook';
import AddBookToWishlist from './components/Wishlist/components/AddBookToWishlist';
import Wishlist from './components/Wishlist/components/Wishlist';
import SignIn from './components/auth/SignIn';

import './App.css';

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <React.Fragment>
            <Header />
            <div className="App-Container">
              <Switch>
                <Route exact path='/' component={SignIn} />
                <Route path='/books/list' component={BooksList} />
                <Route path='/books/add' component={AddBook} />
                <Route path='/wishlist/list' component={Wishlist} />
                <Route path='/wishlist/add' component={AddBookToWishlist} />
              </Switch>
            </div>
          </React.Fragment>
        </BrowserRouter>
    );
  }
}
