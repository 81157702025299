import React from 'react';

import './index.css';

const Bullet = props => {
  return <div className={`Bullet--bullet Bullet--${props.type}`}></div>
}

Bullet.defaultProps = {
  type: 'empty'
}

export default Bullet;
