const initialState = {
  authError: null
};

const authReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SIGNIN_SUCCESS':
      return {
        ...state,
        authError: null
      };
    case 'SIGNIN_ERROR':
      return {
        ...state,
        authError: 'Sign in failed'
      };
    case 'SIGNOUT_SUCCESS':
      return state;
    case 'SIGNOUT_ERROR':
      return state;
    default:
      return state;
  }
};

export default authReducer
