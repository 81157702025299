import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { addBook, resetAddBookSuccess } from '../../../../store/actions/wishlistActions';

import SuccessCard from '../../../common/components/SuccessCard';
import ErrorMessage from '../../../common/components/ErrorMessage';
import RadioGroup from '../../../forms/RadioGroup';
import FormButton from '../../../forms/FormButton';
import InputGroup from '../../../forms/InputGroup';
import FormLabel from '../../../forms/fields/FormLabel';
import FormInput from '../../../forms/fields/FormInput';
import FormTextarea from '../../../forms/fields/FormTextarea';

import './index.css';

class AddBookToWishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      author: '',
      inBookshelf: '',
      format: '',
      pages: '',
      synopsis: '',
      timestamp: '',
      isbn: '',
      errors: [],
      googleApiError: '',
      googleApiRequesting: false,
      showIsbnForm: false
    };

    this.baseState = this.state;
  }

  handleIsbnSearch = (event) => {
    event.preventDefault();

    if(this.state.isnb !== '') {
      this.setState({
        googleApiRequesting: true
      });

      fetch(`https://www.googleapis.com/books/v1/volumes?q=isbn:${this.state.isbn}&fields=totalItems,items(volumeInfo(title,authors,description,pageCount))`)
      .then(response => response.json())
      .then(book => {
        if(book.totalItems !== 0) {
          this.setState({
            title: book.items[0].volumeInfo.title,
            author: book.items[0].volumeInfo.authors,
            synopsis: book.items[0].volumeInfo.description,
            pages: book.items[0].volumeInfo.pageCount,
            googleApiRequesting: false,
            googleApiError: ''
          })
        } else {
          this.setState({
            googleApiRequesting: false,
            googleApiError: 'Il n’y a aucun résultat pour cette recherche'
          })
        }
      })
      .catch(error => {
        this.setState({
          googleApiRequesting: false,
          googleApiError: error
        });
      });
    }
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if(value === 'yes') {
      value = true;
    } else if(value === 'no') {
      value = false;
    }
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let errors = [];

    if (!this.state.title) {
      errors.push('title_blank');
    }

    if (!this.state.author) {
      errors.push('author_blank');
    }

    if (this.state.inBookshelf === '') {
      errors.push('inBookshelf_blank');
    }

    if (this.state.inBookshelf && this.state.format === '') {
      errors.push('format_blank');
    }

    if (errors.length > 0) {
      this.setState({errors});
    } else {
      const date = new Date();
      this.setState({
        timestamp: date.getTime()
      }, () => this.props.addBook(this.state));
    }
  }

  displayError = field => {
    const hasError = this.state.errors.filter(err => field.test(err));
    return hasError.length !== 0 ? <ErrorMessage message="Champ obligatoire" /> : null;
  }

  renderIsbnSearchButton = () => {
    return <FormButton color="second" onClick={() => this.setState({showIsbnForm: !this.state.showIsbnForm})}>Rechercher par ISBN</FormButton>;
  }

  renderIsbnForm = () => {
    return (
      <form onSubmit={this.handleIsbnSearch} ref={(form) => this.form = form} className="WishList--IsbnForm">
        <InputGroup>
          <FormLabel htmlFor="isbn" name="isbn">Recherche par ISBN 10</FormLabel>
          <FormInput type="text" name="isbn" id="isbn" minLength="1" value={this.state.isbn} onChange={this.handleChange} required={true} />
          {this.state.googleApiError ? <ErrorMessage message={this.state.googleApiError} /> : null}
        </InputGroup>
        <FormButton type="submit" color="first">Rechercher</FormButton>
      </form>
    );
  }

  resetForm = () => {
    this.setState(
      this.baseState,
      () => {
        this.form.reset();
        this.props.resetAddBookSuccess();
      }
    );
  }

  renderForm = () => {
    return (
      <React.Fragment>
        {this.state.showIsbnForm ? this.renderIsbnForm() : this.renderIsbnSearchButton()}

        <form onSubmit={this.handleSubmit} ref={(form) => this.form = form}>
          <InputGroup>
            <FormLabel htmlFor="title" name="title">Titre</FormLabel>
            <FormInput name="title" id="title" minLength="1" value={this.state.title} onChange={this.handleChange} />
            {this.displayError(/title_/)}
          </InputGroup>

          <InputGroup>
            <FormLabel htmlFor="author" name="author">Auteur</FormLabel>
            <FormInput id="author" name="author" minLength="1" value={this.state.author} onChange={this.handleChange} />
            {this.displayError(/author_/)}
          </InputGroup>

          <RadioGroup legend="Déjà dans ma bibliothèque ?">
            <FormInput type="radio" value="yes" id="yes" name="inBookshelf" onChange={this.handleChange} />
            <FormLabel htmlFor="yes">Oui</FormLabel>
            <FormInput type="radio" value="no" id="no" name="inBookshelf" onChange={this.handleChange} />
            <FormLabel htmlFor="no">Non</FormLabel>
            {this.displayError(/inBookshelf_/)}
          </RadioGroup>

          <RadioGroup legend="Format ?">
            <FormInput type="radio" value="Poche" id="poche" name="format" onChange={this.handleChange} />
            <FormLabel htmlFor="poche">Poche</FormLabel>
            <FormInput type="radio" value="Kindle" id="kindle" name="format" onChange={this.handleChange} />
            <FormLabel htmlFor="kindle">Kindle</FormLabel>
            {this.displayError(/format_/)}
          </RadioGroup>

          <InputGroup>
            <FormLabel htmlFor="pages" name="pages">Nombre de pages</FormLabel>
            <FormInput name="pages" id="pages" minLength="1" value={this.state.pages} onChange={this.handleChange} />
          </InputGroup>

          <InputGroup>
            <FormLabel htmlFor="synopsis">Synopsis</FormLabel>
            <FormTextarea id="synopsis" name="synopsis" minLength="15" rows="3" value={this.state.synopsis} onChange={this.handleChange}></FormTextarea>
          </InputGroup>

          <FormButton type="reset" color="second" onClick={this.resetForm}>Reset</FormButton>
          <FormButton type="submit" color="first">Ajouter</FormButton>
        </form>
      </React.Fragment>
    );
  };

  render() {
    const { auth, addBookSuccess } = this.props;

    if (!auth.uid) {
      return <Redirect to='/' />
    }

    return (
      <div className="AddBookToWishList--Container">
        { addBookSuccess
          ? <SuccessCard
             onClick={this.resetForm}
             text="Le livre a bien été ajouté !"
             buttonText="Ajouter un nouveau livre"
             navigationLink="/wishlist/list"
             navigationText="Retourner sur la liste"
            />
          : this.renderForm()
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    addBookSuccess: state.wishlist.addBookSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBook: book => dispatch(addBook(book)),
    resetAddBookSuccess: () => dispatch(resetAddBookSuccess())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBookToWishList);
