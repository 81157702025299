import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

import sort from 'fast-sort';

import { deleteBook } from '../../../../store/actions/wishlistActions';

import ListItem from '../../../common/components/ListItem';
import Head2 from '../../../common/components/Head2';
import Head3 from '../../../common/components/Head3';
import ExternalLink from '../../../common/components/ExternalLink';
import Cta from '../../../common/components/Cta';
import Spinner from '../../../common/components/Spinner';
import Control from '../../../common/components/Control';

import CheckIcon from './assets/check.svg';

import './index.css';

class Wishlist extends Component {
  state = {
    displayAllDetails: false,
    displayDetails: '',
    displayDetailsText: 'Vue détaillée',
    sort: 'date',
    loadingBooks: true,
    sortOrder: 'asc'
  };

  renderSearchLinks = book => {
    const amazon = `https://www.amazon.fr/s/ref=nb_sb_noss_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias%3Daps&field-keywords=${book.title} ${book.author}`;
    const parisLibrairies = `https://www.parislibrairies.fr/listeliv.php?mots_recherche=${book.title} ${book.author}`;

    if(!book.inBookshelf) {
      return (
        <React.Fragment>
          <ExternalLink link={amazon}>Amazon</ExternalLink>
          <ExternalLink link={parisLibrairies}>Paris librairies</ExternalLink>
        </React.Fragment>
      );
    }

    return null;
  }

  toggleDetails = id => {
    if (id !== this.state.displayDetails) {
      this.setState({ displayDetails: id });
    } else {
      this.setState({ displayDetails: '' });
    }
  }

  toggleAllDetails = () => {
    this.setState({
      displayAllDetails: !this.state.displayAllDetails,
      displayDetails: ''
    });
  }

  delete = id => {
    const confirmDeletion = confirm('Etes-vous sur de vouloir supprimer ce livre ?');
    if (confirmDeletion) {
      this.props.deleteBook(id);
    }
  }

  renderSortBar = () => {
    const total = `${this.props.wishlist.length} livres à lire`;

    return (
      <div className="BooksList--header">
        <p className="BooksList--total">{total}</p>
        <button className="BooksList--button" onClick={this.toggleAllDetails}>{this.state.displayDetailsText}</button>
        <button className="BooksList--button" onClick={this.toggleSortOrder}>Trier</button>
      </div>
    );
  }

  renderReview = book => {
    if(this.state.displayDetails === book.id || this.state.displayAllDetails) {
      return (
        <React.Fragment>
          {book.synopsis ? <p className="Wishlist--review Wishlist--synopsis">{book.synopsis}</p> : null}
          <div className="Wishlist--SearchLinks">
            {this.renderSearchLinks(book)}
            <Cta onClick={() => this.delete(book.id)}>Supprimer</Cta>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }

  }

  toggleSortOrder = () => {
    const sortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({sortOrder});
  }

  sortList = (collection, entry) => {
    // Because the array is frozen in strict mode, we need to copy the array before sorting it
    collection = collection.slice();

    if(this.state.sortOrder === 'asc') {
      return sort(collection).asc(entry);
    }

    return sort(collection).desc(entry);
  }

  render() {
    const { auth, wishlist } = this.props;

    if (!auth.uid) {
      return <Redirect to='/' />
    }

    if (!wishlist) {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        {this.renderSortBar()}
        <ul className="Wishlist--List">
          {wishlist && this.sortList(wishlist, 'inBookshelf').map((book, index) => (
            <ListItem key={index}>
              <a className="Wishlist--link" onClick={() => this.toggleDetails(book.id)}>
                <Head2>{book.title}</Head2>
                <Head3>{book.author}</Head3>
                {book.pages ? <p className="Wishlist--pages">{book.pages} pages</p> : null}
                {book.inBookshelf ? <p className="Wishlist--inBookshelf"><CheckIcon className="Wishlist--checkIcon" /> {book.format}</p> : null}
              </a>
                {this.renderReview(book)}
            </ListItem>
          ))}
        </ul>
        <Control scroll={true} addToWishlist={true} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    wishlist: state.firestore.ordered.wishlist
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteBook: id => dispatch(deleteBook(id))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'wishlist' }
  ])
)(Wishlist);
