import React from 'react';

import './index.css';

const FormTextarea = props => {
  return (
    <textarea
     className="FormTextarea"
     id={props.id}
     name={props.name}
     minLength={props.minLength}
     rows={props.rows}
     value={props.value}
     onChange={props.onChange}
     required={props.required}
    >
    </textarea>
  );
};

export default FormTextarea;
