import React from 'react';

import Navbar from '../Navbar';

import './index.css';

const Header = () => {
  return (
    <header className="MainHeader">
      <div className="HeaderContainer">
        <Navbar />
      </div>
    </header>
  )
};

export default Header;
