const initialState = {};

const bookReducer = (state = initialState, action) => {
  switch(action.type) {
      case 'ADD_BOOK_TO_WISHLIST':
      return {
        ...state,
        addBookSuccess: true
      };
      case 'ADD_BOOK_TO_WISHLIST_RESET':
      return {
        ...state,
        addBookSuccess: false
      };
      case 'ADD_BOOK_TO_WISHLIST_ERROR':
      return {
        ...state,
        addBookSuccess: false
      };
      case 'DELETE_BOOK_SUCCESS':
      return {
        ...state,
        deleteBookSucess: true
      };
      case 'DELETE_BOOK_ERROR':
      return {
        ...state,
        deleteBookError: true
      };
      case 'RESET_ADD_BOOK_TO_WISHLIST':
      return {
        ...state,
        addBookSuccess: false
      };
    default:
      return state;
  }
};

export default bookReducer
