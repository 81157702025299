import authReducer from './authReducer';
import bookReducer from './bookReducer';
import wishlistReducer from './wishlistReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  book: bookReducer,
  wishlist: wishlistReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer;
