import React, {useState} from 'react';
import {connect} from 'react-redux';
import {signIn} from '../../../store/actions/authActions';

import './index.css';

import ErrorMessage from '../../common/components/ErrorMessage';
import InputGroup from '../../forms/InputGroup';
import FormLabel from '../../forms/fields/FormLabel';
import FormInput from '../../forms/fields/FormInput';
import FormButton from '../../forms/FormButton';

const SignIn = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleChange = e => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    }
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    props.signIn({email, password});
  }

  const renderForm = () => {
    const {authError} = props;

    return (
      <div className="SignIn--Container">
        <form onSubmit={handleSubmit}>
          <InputGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <FormInput type="text" id="email" name="email" required="" onChange={handleChange} value={email} />
          </InputGroup>
          <InputGroup>
            <FormLabel htmlFor="empasswordail">Password</FormLabel>
            <FormInput type="password" id="password" name="password" onChange={handleChange} required="" value={password} />
          </InputGroup>
          <FormButton type="submit" color="first">Login</FormButton>
          {authError ? <ErrorMessage message={`Error: ${authError}`} /> : null}
        </form>
      </div>
    );
  }

  const renderSignedIn = () => {
    const {email} = props.auth;
    return (
      <div className="SignIn--Container">
        <h1 className="SignIn--SignedInTitle">Bienvenue {email} !</h1>
        <p className="SignIn--SignedInText">Vous êtes connecté</p>
      </div>
    );
  }

  const {auth} = props;
  return auth.uid ? renderSignedIn() : renderForm();
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => dispatch(signIn(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
